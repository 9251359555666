exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bilder-hygienanlaggningen-js": () => import("./../../../src/pages/bilder/hygienanlaggningen.js" /* webpackChunkName: "component---src-pages-bilder-hygienanlaggningen-js" */),
  "component---src-pages-bilder-js": () => import("./../../../src/pages/bilder.js" /* webpackChunkName: "component---src-pages-bilder-js" */),
  "component---src-pages-bilder-omradet-js": () => import("./../../../src/pages/bilder/omradet.js" /* webpackChunkName: "component---src-pages-bilder-omradet-js" */),
  "component---src-pages-bilder-patrullstugorna-js": () => import("./../../../src/pages/bilder/patrullstugorna.js" /* webpackChunkName: "component---src-pages-bilder-patrullstugorna-js" */),
  "component---src-pages-bilder-storstugan-js": () => import("./../../../src/pages/bilder/storstugan.js" /* webpackChunkName: "component---src-pages-bilder-storstugan-js" */),
  "component---src-pages-bilder-timmerstugorna-js": () => import("./../../../src/pages/bilder/timmerstugorna.js" /* webpackChunkName: "component---src-pages-bilder-timmerstugorna-js" */),
  "component---src-pages-stugor-js": () => import("./../../../src/pages/stugor.js" /* webpackChunkName: "component---src-pages-stugor-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

